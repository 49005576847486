import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { EllipseIcon } from '../../assets/SVG';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import DiscoverCardReader from './DIscoverCardReader';
import SettingHeadingTitle from './SettingHeadingTitle';
import SettingSidebar from './SettingSidebar';
import {connect} from "react-redux";
import {paymentActions} from "../../_actions";
import LoaderSetting from "./Loader";
import siteSetting from "../../config/env";
import {loadStripeTerminal} from "@stripe/terminal-js";
import {paymentService} from "../../_services";
import {userActions} from "../../_actions";
import {Dropdown} from 'react-bootstrap';
import {InfoIcon , Close} from '../../component/SvgIcon';
import { Dots } from "../SvgIcon";
var terminal;


loadStripeTerminal().then(function (StripeTerminal) {
    terminal = StripeTerminal.create({
        onFetchConnectionToken: fetchConnectionToken,
        onUnexpectedReaderDisconnect: unexpectedDisconnect,
    })
    console.log("terminal", terminal);
})

function fetchConnectionToken(){
    let self=this;
    return paymentService.fetchConnectionToken()
        .then(function (res){
            console.log("res",res.data);
            return res.data.responseData.result.secret;
        })
}

function unexpectedDisconnect(){

}
class CardReader extends Component{
    constructor(props){
        super(props);
        this.state = {
            isCardReaderRequested:false,
            cardReaderRequestError:'',
            registrationCode:'',
            cardReaderConnectError:'',
            locationId:'',
            isCardConnected:true,
            activeCLass: false,
            connectDevice: false,
            isSearchReader:false,
            cardReaderFound:true,
            discoverResult:[],
            noDevice:false,
            selectedDevice:{},
            cardReaderList:[],
            label:'',
            isTransactionEdit:false,
            selectedTransaction:1,
            showTooltip:false
        }
    }
    showTooltip=()=>{
        this.setState({
            showTooltip:true
        })
     }
     hideTooltip=()=>{
        this.setState({
            showTooltip:false
        })
     }
    componentDidMount() {
        let self=this;
        if (localStorage.getItem('accessToken')) {
            let params={
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {
                    //console.log("response",response.data.data);
                    if(response.data.data) {
                        self.setState({
                            locationId:response.data.data.userInfo.hairdresser.stripeLocationId?response.data.data.userInfo.hairdresser.stripeLocationId:"",
                            selectedTransaction:response.data.data?.userInfo?.hairdresser?.transactionFeeType?response.data.data.userInfo.hairdresser.transactionFeeType:1,
                        })
                        if(response.data.data.userInfo?.hairdresser?.isSalonOwner===1){
                            self.setState({
                                label:response.data.data.userInfo?.hairdresser?.salon.salonName?response.data.data.userInfo?.hairdresser?.salon?.salonName+" Card Reader":""
                            })
                        }
                        self.cardReaderList(response.data.data.userInfo);
                    }
                })

        }else{
            self.props.history.push('/')
        }
        // document.body.classList.add('setting-temps-pages');
    }
    componentWillUnmount(){
        //console.log("componentWillUnmount#####",this.props.user?.userInfo?.hairdresser?.cardReaderRequest);
        document.body.classList.remove('setting-temps-pages');
    }

    requestCardReader=()=>{
        let self=this;
        self.props.dispatch(paymentActions.requestCardReader({}))
            .then(function (response) {
                console.log("response", response.data);
                if (response.data.statusCode===1) {
                    self.setState({
                        isCardReaderRequested:true
                    })
                }else{
                    self.setState({
                        cardReaderRequestError:response.data.error.errors.responseMessage
                    })
                }
            });
    }
    addStripeTerminal=()=>{
        let self=this;
        self.props.dispatch(paymentActions.addTerminal({registrationCode:this.state.registrationCode,label:this.state.label}))
            .then(function (response) {
                console.log("response", response.data);
                if (response.data.statusCode===1) {
                    self.cardReaderList();
                    self.setState({
                        isSearchReader:false,
                        isCardReaderRequested:true
                    })
                }else{
                    self.setState({
                        cardReaderConnectError:response.data.error.responseMessage
                    })
                }
            });
    }
    disconnectCardReader=(deviceId)=>{
        let self=this;
        // await terminal.disconnectReader();
        // this.setState({
        //     isCardConnected: false
        // })
        self.props.dispatch(paymentActions.cardReaderDelete({deviceId:deviceId}))
            .then(function (response) {
                console.log("response", response.data);
                if (response.data.statusCode===1) {
                    self.cardReaderList();
                    self.setState({
                        isCardConnected: false
                    })
                }else{

                }
            })
    }

    handleDiscoverCardReader = (searchDevice)=>{
        this.setState({activeCLass: !this.state.activeCLass,
            selectedDevice:searchDevice
        })
    }
    handleConnectDevice = ()=>{
        this.setState({connectDevice: !this.state.connectDevice})
    }
    handleCancel = ()=>{
        this.setState({
            registrationCode:'',
            cardReaderConnectError:'',
            isSearchReader:false
            //activeCLass: false,
            //connectDevice: false
        })
    }

    discoverReaders=()=>{
        let self=this;
        this.setState({
            activeCLass:true,
            isSearchReader:true
        })
        const config = {simulated: siteSetting.simulated, location: this.state.locationId};
        console.log("terminal",terminal);
        terminal.discoverReaders(config).then(function(discoverResult) {
            // self.setState({
            //     isSearchReader:false
            // })
            if (discoverResult.error) {
                console.log('Failed to discover: ', discoverResult.error);
            } else if (discoverResult.discoveredReaders.length === 0) {
                self.setState({
                    noDevice:true,
                })
                console.log('No available readers.');
            } else {
                // You should show the list of discoveredReaders to the
                // cashier here and let them select which to connect to (see below).
                //this.connectReader(discoverResult);
                self.setState({
                    discoverResult:discoverResult.discoveredReaders
                })
            }
        });
    }

    connectReader=()=> {
        let selectedReader=this.state.selectedDevice
        //console.log("selectedReader",selectedReader);
        let self=this;
        // Just select the first reader here.
        //var selectedReader = discoverResult.discoveredReaders[0];

        terminal.connectReader(selectedReader).then(function (connectResult) {
            if (connectResult.error) {
                self.setState({
                    isCardConnected:false
                })
                console.log('Failed to connect: ', connectResult.error);
            } else {
                console.log('Connected to reader: ', connectResult.reader.label);
                self.cardReaderList();
                self.setState({
                    isCardConnected:true
                })
            }
        });
    }

    cardReaderList=(hairdresserInfo)=>{
        let self=this;
        self.props.dispatch(paymentActions.cardReaderList({}))
            .then(function (response){
                if (response.data.statusCode===1) {
                    self.setState({
                        cardReaderList: self.props.cardReaderList
                    },function (){
                        if(hairdresserInfo?.hairdresser?.isSalonOwner===1) {
                            let count=0;
                            const names = self.state.cardReaderList.map(obj=>{
                                count++
                            })
                            self.setState({
                                label: hairdresserInfo?.hairdresser?.salon.salonName ? hairdresserInfo?.hairdresser?.salon?.salonName + ` Card Reader (${count+1})` : ""
                            })
                        }
                    });
                }else{

                }
            })
    }

    onChanged=(e)=> {
        let self=this;
        self.setState({[e.target.name]: e.target.value});

        // if (e.target.name === 'first_name') {
        //     if (e.target.value === '' || e.target.value === null) {
        //         this.setState({
        //             fNameError: ''
        //         })
        //     } else {
        //         this.setState({
        //             fNameError: '',
        //         })
        //     }
        // }
    }

    editTransaction=()=>{
        let self=this;
        self.setState({
            isTransactionEdit:true
        });
    }

    chnageTransaction=(value)=>{
        let self=this;
        if(self.state.isTransactionEdit===true) {
            self.setState({
                selectedTransaction: value
            });
        }
    }

    saveTransactionFee=()=>{
        let self=this;
        self.setState({
            isTransactionEdit:false
        });
        let params={
            transactionFeeType:this.state.selectedTransaction
        }
        self.props.dispatch(paymentActions.updateTransactionFee(params))
            .then(function (response) {
                console.log("response",response);
            })
    }

    render(){
        let props=this.props;
        console.log("this.props",this.props);
        console.log("this.state",this.state);
        return(
            <>
            <div className="h_n">
                <div className="body_container cm_bg_white setting_page">
                    <TopHeader title="Card Readers" {...this.props}/>
                    <SideNav {...this.props}/>
                        <div className='new-setting-wrapper'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <SettingHeadingTitle title="Card Readers" />
                                        <SettingSidebar />
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='salon-body-main-wrp account--payout-wrp card-readeers--mob-view'>

                                            <div className='new-stng-cmn account-pauout-balance mb-3 mb-md-4 order-4'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='card-readerWrp'>
                                                            <h4 className='heading-30 d-block d-lg-none'>Card Reader</h4>
                                                            <img className='img-responsive' src={require('../../assets/images/card-reader.png')}  />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='card-reader-crd-desc pl-md-3 mt-lg-0 mt-3 text-center'>
                                                            <h4 className='heading-30 d-none d-lg-block text-left'>Card Reader</h4>
                                                            <p className='text-left'>Beu fully integrates with the BBPOS WisePOS E to process card and mobile transactions seamlessly.</p>
                                                            {/* {this.state.isCardReaderRequested===false?<button className='btn btn-black w-100' onClick={()=>this.requestCardReader()}>Request Card Reader</button>:<button className='btn btn-req w-100'>Card Reader Requested</button>} */}
                                                            <a href="https://buy.stripe.com/6oE29f3S432E4mcbIL" className='btn btn-black w-100' target="_blank">Order Card Reader</a>
                                                                {this.state.isCardReaderRequested===true?<div className='beuSprt pt-2 text-left'><p className='mb-0'>Someone from Beu will be in touch to organise your card reader</p></div>:""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='new-stng-cmn card-reader-list mb-3 mb-md-4 order-1 pos-relative'>
                                            <div className='tooltip_parent'>
                                                <span className='info' onClick={() =>this.showTooltip()}><InfoIcon/></span>
                                                {this.state.showTooltip===true?
                                                <div className='tooltip-body'>
                                                  <span className='close' onClick={() =>this.hideTooltip()}><Close/></span>
                                                  <h5>Access SalonSettings</h5>
                                                  <p>Enter the admin pin 07139 to access settings.</p>
                                               
                                                </div>:''}
                                             </div>
                                                <div className='card-discoversDes card-discover-top'>
                                                    <h4>Connected Card Readers</h4>
                                                    {this.state.isCardConnected===false||this.state.cardReaderList.length===0? <p>There are currently no connected card readers</p>:""}
                                                </div>
                                                {this.state.isCardConnected===true&&this.state.cardReaderList.length>0?
                                                    this.state.cardReaderList.map((device) => (
                                                <div key={'cr-'+device.id} className='discover-card-reader-lst active-card connected-card-reder-mob'>

                                                    <div className='row align-items-center'>
                                                        <div className='col-md-3 col-5'>
                                                            <div className='disccover-crd-redr-img'>
                                                                <img className='img-responsive' src={require('../../assets/images/card-readerImg.png')}  />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5 col-7'>
                                                            <div className='disccover-crd-redr-descr'>
                                                                <h4>{device.device_type}</h4>
                                                                <p>{device.label}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4 col-12'>
                                                            <div className='disconnects text-center text-lg-right'>
                                                                <button className='btn btn-disc' onClick={()=>this.disconnectCardReader(device.id)}>Disconnect</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)):""}
                                            </div>
                                          
                                            {/*<DiscoverCardReader />*/}

                                            <div className='new-stng-cmn card-reader-list mb-3 mb-md-4 order-2'>
                                                <div className='card-discover-top card-discoversDes'>
                                                    <h4>Discovered Card Readers</h4>
                                                    {/*{this.state.isSearchReader===true?<p>Searching for card readers… <LoaderSetting /></p>:""}*/}
                                                    <div className='search-discoveed-reader d-flex flex-wrap align-items-center'>
                                                        {/*{<p className='mb-0'>No card readers found</p>}*/}
                                                        {this.state.isSearchReader===false?<button className='btn btn-inf ml-auto' onClick={()=>this.discoverReaders()}>Search for Card Reader</button>:""}
                                                    </div>
                                                </div>
                                                {this.state.isSearchReader===true?
                                                    // this.state.cardReaderFound===true||
                                                    <>
                                                    <div className={`discover-card-reader-lst ${this.state.activeCLass ? 'active-card' : ''}`}>
                                                        <div className='row align-items-center'>

                                                            <div className='col-md-3 col-5'>
                                                                <div className='disccover-crd-redr-img'>
                                                                    <img className='img-responsive' src={require('../../assets/images/card-readerImg.png')}  />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-9 col-7'>
                                                                <div className='disccover-crd-redr-descr'>
                                                                    <h4>BBPOS WisePOS E</h4>
                                                                    <p>Device 2316</p>
                                                                </div>
                                                            </div>

                                                            <div className='col-md-12 col-12'>
                                                                <div className='card-discover-access-code pt-3 pt-md-4'>
                                                                <p> Turn on your BBPOS WisePOS E and connect it to the same WIFI network as the device running Beu.</p>
                                                                <p className='li-tag'><b>1.)</b> <div>Once connected, swipe from the left and go into setting</div>s</p>
                                                                <p className='li-tag'><b>2.)</b> <div>Enter this admin pin onto the reader <b>07139</b> and then select generate pairing code</div> </p>
                                                                <p className='li-tag mb-5'><b>3.)</b> <div>Enter the 3 word pairing code displayed on your BBPOS WisePOS E</div></p>
                                                                    {/* <p>Turn on your BBPOS WisePOS E and connect it to the same WIFI network as the device running Beu. Once connected, please enter the access code displayed on BBPOS WisePOS E.</p> */}
                                                                    <div className='form-group'>
                                                                        <label>Pairing Code</label>
                                                                        <input className='form-control' type="text" placeholder='frog-kiss-settle' value={this.state.registrationCode} name="registrationCode" onChange={this.onChanged}/>
                                                                    </div>
                                                                    <div className='form-group'>
                                                                        <label>Device Name</label>
                                                                        <input className='form-control' type="text" value={this.state.label} name="label" onChange={this.onChanged}/>
                                                                    </div>
                                                                    <div className='form-group mb-0 text-center text-lg-right btn-Readerss pt-md-3'>
                                                                        <button className='btn mr-md-3 btn-line-blck' onClick={()=>this.handleCancel()}>Cancel </button>
                                                                        <button className='btn btn-inf px-md-5' onClick={()=>this.addStripeTerminal()}>Confirm</button>
                                                                    </div>
                                                                </div>
                                                                {this.state.cardReaderConnectError ?
                                                                    <span className="cm_error_msg">{this.state.cardReaderConnectError}</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*{this.state.discoverResult.length>0?*/}
                                                    {/*        this.state.discoverResult.map((searchDevice)=>(<>*/}
                                                    {/*<div onClick={()=>this.handleDiscoverCardReader(searchDevice)} className={`discover-card-reader-lst ${this.state.activeCLass ? 'active-card' : ''}`}>*/}
                                                    {/*    <div className='row align-items-center'>*/}

                                                    {/*        <div className='col-md-3 col-5'>*/}
                                                    {/*            <div className='disccover-crd-redr-img'>*/}
                                                    {/*                <img className='img-responsive' src={require('../../assets/images/card-readerImg.png')}  />*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className='col-md-9 col-7'>*/}
                                                    {/*            <div className='disccover-crd-redr-descr'>*/}
                                                    {/*                <h4>{searchDevice.device_type}</h4>*/}
                                                    {/*                <p>{searchDevice.label}</p>*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*    {this.state.activeCLass && (this.state.connectDevice ? '' : <div className='tick-Check'>*/}
                                                    {/*        <img className='img-responsive' src={require('../../assets/images/tick-check.png')}  />*/}
                                                    {/*    </div>) }*/}
                                                    {/*</div></>)):""}*/}
                                                    </>:""}
                                                {/*{this.state.activeCLass && (this.state.connectDevice ? '' : <div className='conec-dvce-btn text-center text-lg-right mt-4'>*/}
                                                {/*    <button className='btn btn-inf' onClick={()=>this.connectReader()}>Connect Device</button>*/}
                                                {/*</div>)}*/}
                                            </div>
                                            {/* -Transaction Fees--- */}
                                            {props.user&&props.user.userInfo&&props.user.userInfo?.hairdresser?.isSalonOwner===1?
                                            <div className='new-stng-cmn card-reader-list mb-3 mb-md-4 order-3 pos-relative'>
                                                {this.state.isTransactionEdit===false?
                                                <Dropdown className="transaction_Dropdown">
                                                        <Dropdown.Toggle  id="dropdown-basic">
                                                           <span> <Dots/></span>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#" onClick={()=>this.editTransaction()}>Edit</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                </Dropdown>:""}
                                                <div className='card-discoversDes card-discover-top'>
                                                    <h4>Transaction Fees</h4>
                                                    {/*<p>The transaction fees for payments made with the Beu Card Reader and for online payments will be paid by:</p>*/}
                                                    <p>The transaction fees for payments made with Beu Card Readers, online deposits and cards on file will be paid by:</p>
                                                </div>
                                                <div className={this.state.selectedTransaction===1?'transaction_option selected':"transaction_option"} onClick={()=>this.chnageTransaction(1)}>
                                                   <p>Self Employed Staff </p>
                                                   <label className="radio-container ">
                                                     <input type="radio" checked={this.state.selectedTransaction===1?true:false} name="radi1" />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                </div>
                                                <div className={this.state.selectedTransaction===2?'transaction_option selected':"transaction_option"} onClick={()=>this.chnageTransaction(2)}>
                                                    <p>Salon (salon manager account)</p>
                                                   <label className="radio-container"> 
                                                     <input type="radio" checked={this.state.selectedTransaction===2?true:false} name="radi2" />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                    
                                                </div>
                                                {this.state.isTransactionEdit?
                                                <div className='transaction_btn text-right mt-4'>
                                                    <button className='btn btn-black ' onClick={()=>this.saveTransactionFee()}>Save Changes</button>
                                                </div>
                                                    :""}
                                            </div>:""}
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(CardReader);


